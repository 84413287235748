const directoryLinks = document.querySelectorAll('.Directory-link')
const chapters = document.querySelectorAll('.MatrixChapter')
const directory = document.querySelector('.Directory')

function getTopVisibleChapter() {
  const marginTop = 200 // scroll-margin-top Wert

  const visible = Array.from(chapters)
    .map((chapter) => ({
      el: chapter,
      rect: chapter.getBoundingClientRect(),
    }))
    .filter(({ rect }) => {
      return rect.top + marginTop < window.innerHeight && rect.top > 0
    })
    .sort((a, b) => a.rect.top - b.rect.top)

  return visible.length > 0 ? visible[0].el : null
}

function updateActiveChapter() {
  const topVisibleChapter = getTopVisibleChapter(chapters)

  if (topVisibleChapter) {
    const id = topVisibleChapter.id
    directoryLinks.forEach((link) => {
      link.classList.toggle(
        'Directory-link--active',
        link.getAttribute('href') === `#${id}`,
      )
    })
  }
}

if (directoryLinks) {
  directoryLinks.forEach((link) => {
    link.addEventListener('click', () => {
      const targetElement = document.querySelector(
        link.getAttribute('href') + ' .MatrixChapter-details',
      )
      if (targetElement && !targetElement.open) {
        targetElement.open = true
      }

      setTimeout(() => {
        directory.classList.add('Directory--temporarilyClosed')
        document.activeElement.blur()
      }, 100)

      setTimeout(() => {
        directory.classList.remove('Directory--temporarilyClosed')
      }, 700)
    })
  })

  if (chapters) {
    document.addEventListener('scroll', () => {
      updateActiveChapter()
    })
    document.addEventListener('resize', () => {
      updateActiveChapter()
    })
    document.addEventListener('DOMContentLoaded', () => {
      updateActiveChapter()
    })
  }
}
