const matrixChapterDetails = document.querySelectorAll('.MatrixChapter-details')

if (matrixChapterDetails) {
  Array.prototype.forEach.call(matrixChapterDetails, function (chapter) {
    if (window.innerWidth < 750) {
      chapter.open = false
    }
  })
}

const matrixChapters = document.querySelectorAll('.MatrixChapter')

if (matrixChapters) {
  window.addEventListener('hashchange', () => {
    matrixChapters.forEach((chapter) => {
      chapter.classList.remove('MatrixChapter--dimmed')
    })

    const targetId = window.location.hash.slice(1)
    const targetElement = document.getElementById(targetId)

    if (!targetElement) return

    // Alle außer dem Ziel dimmen
    matrixChapters.forEach((chapter) => {
      if (chapter !== targetElement) {
        chapter.classList.add('MatrixChapter--dimmed')
      }
    })

    // Nach kurzer Zeit wieder den Dim-Effekt aufheben
    setTimeout(() => {
      matrixChapters.forEach((chapter) => {
        chapter.classList.remove('MatrixChapter--dimmed')
      })
    }, 500) // Der Effekt bleibt 0.5s aktiv, entsprechend der Übergangszeit
  })
}
